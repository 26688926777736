import axios from "axios";

import { serverUri } from "./serverUri";

export const getAlerts = async (token) => {
  const response = await axios.get(`${serverUri}/alerts`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const uploadImage = async (file, authToken) => {
  const formData = new FormData();
  formData.append("files", file);

  const response = await fetch(`${serverUri}/upload`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    body: formData,
  });

  if (!response.ok) {
    throw new Error("Error al subir la imagen");
  }

  const data = await response.json();
  return data[0]; // Devuelve la información del primer archivo subido
};

export const createAlert = async (data, token) => {
  const response = await axios.post(`${serverUri}/alerts`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const updateAlert = async (id, data, token) => {
  const response = await axios.put(`${serverUri}/alerts/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const deleteAlert = async (id, token) => {
  const response = await axios.delete(`${serverUri}/alerts/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
